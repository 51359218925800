import axios from 'axios'; //axios请求方法使用axios进行请求处理，不要再多说，直接

// const upLoadOss = require('../../api/app.js');
import { ref, createApp, watch } from 'vue';
import { NavBar, ConfigProvider, Divider, Field, CellGroup, Form, Tab, Tabs } from 'vant';
import { useCookies } from '@vueuse/integrations/useCookies';
const cookies = useCookies(); // 使用 useCookies 获取 cookies 对象
const app = createApp();
app.use(NavBar).use(ConfigProvider).use(Divider).use(Field).use(CellGroup).use(Form).use(Tab).use(Tabs);
export default {
  setup() {
    const fileList1 = ref({
      title: '',
      content: '',
      list: []
    });
    const fileList2 = ref({
      title: '',
      content: '',
      list: []
    });
    const fileList3 = ref({
      title: '',
      content: '',
      list: []
    });
    const value1 = ref('');
    const value2 = ref('');
    const message1 = ref('');
    const message2 = ref('');
    const publish1 = ref(true);
    const publish2 = ref(true);
    const publish3 = ref(true);
    const objData = ref({
      OSSAccessKeyId: '',
      policy: '',
      Singnature: '',
      key: '',
      host: '',
      dir: ''
    });
    const imgListI = ref([]);
    const video = ref([]);
    const imgListIII = ref([]);
    const onClickLeft = () => history.back();
    const active = ref(0);
    const afterReadI = files => {
      fileList1.value.list = imgListI;
      console.log('f1', fileList1);
    };
    const afterReadII = files => {
      console.log(files.file);
      fileList2.value.list = files.file;
    };
    const afterReadIII = files => {
      console.log(files.file);
      fileList3.value.list = files.file;
    };

    // 当 fileList1.title 或 fileList1.content 改变时，检查它们是否为空  
    watch(fileList1.value, (newVal, oldVal) => {
      if (newVal.title === '' || newVal.content === '') {
        publish1.value = true;
        console.log('233333', newVal.list);
      } else {
        publish1.value = false;
        // console.log('2333', newVal.title);
      }
    }, {
      immediate: true
    });
    return {
      onClickLeft,
      active,
      fileList1,
      fileList2,
      fileList3,
      afterReadI,
      afterReadII,
      afterReadIII,
      value1,
      value2,
      message1,
      message2,
      publish1,
      publish2,
      publish3,
      objData,
      imgListI,
      video,
      imgListIII
    };
  },
  data() {
    return {};
  },
  components() {
    // upLoad
  },
  methods: {
    // ceshiacount() {
    //     return new Promise((resolve, reject) => {
    //         this.axios.get('http://farm-service-oc.xueshangkeji.com/uaa/api/account',)
    //             .then((response) => {
    //                 console.log('ceshi', response);
    //             })
    //             .catch((error) => {
    //                 console.log(error); // error from promise.then() or.catch()

    //             })
    //     });
    // },
    ceshiacount() {
      const accountLogin = cookies.get('accountLogin');
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: 'https://farm-service-oc.xueshangkeji.com/auth/login',
          data: {
            password: "farm",
            rememberMe: true,
            username: accountLogin
          },
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (response) {
          console.log('登录后');
          const {
            access_token,
            expires_in
          } = response.data;
          cookies.set('access_token', access_token);
          cookies.set('expires_in', expires_in);
          const newLoginTime = new Date(); // 更新登录时间  
          cookies.set('reloginTime', newLoginTime);
          // console.log('refresh', access_token);
          resolve(response); // 这里可以根据需要返回需要的数据，或者将两个接口的结果合并后返回    
        }).catch(function (error) {
          console.log(error);
        });
      });
    },
    ossPolicy(files) {
      let _self = this;

      // console.log('cookies', cookies);
      return new Promise((resolve, reject) => {
        //请求后端
        this.axios.get('https://farm-service-oc.xueshangkeji.com/uaa/api/schools/test0089', {
          headers: {
            'Access-Token': cookies.get('access_token'),
            // 从 cookies 中获取 access_tocken 并放入请求头中  
            'Refresh-Token': cookies.get('refresh_token'),
            // 从 cookies 中获取 refresh_tcken 并放入请求头中  
            Authorization: "Bearer " + cookies.get('access_token'),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        //当是使用箭头函数 this指向的是外层
        .then(response => {
          _self.objData.OSSAccessKeyId = response.data.accessid; //获取accessKeyId secretAccessKey secretAccessKey的值。 
          _self.objData.policy = response.data.policy;
          _self.objData.Signature = response.data.signature;
          _self.objData.dir = response.data.dir;
          _self.objData.host = response.data.host;
          // 假设你有一个用户类型_userType和上传时间_uploadTime的数据  
          let _userType = 'Article'; // 替换为你的用户类型数据  
          let _uploadTime = new Date().toISOString(); // 获取当前时间的 ISO 格式
          let fliename = 'example.json'; // 这里你需要赋值你需要的文件名  

          // 将用户类型和上传时间加入文件名中  
          _self.objData.key = response.data.dir + `${_userType}/${_uploadTime}/${fliename}`;
        }).catch(error => {
          console.log(error); // error from promise.then() or.catch()
          console.log('errorcookies', cookies.get('access_token'));
        });
      });
    },
    async beforerRead(files) {
      return true;
    },
    async handleUpload(files) {
      let _self = this;
      console.log('23333', files);
      console.log('23333===', this.fileList1);
      let formData = new FormData();
      formData.append('key', this.objData.key);
      formData.append('OSSAccessKeyId', this.objData.OSSAccessKeyId);
      formData.append('policy', this.objData.policy);
      formData.append('Signature', this.objData.Signature);
      formData.append('file', JSON.stringify(this.fileList1));
      // 如果fileList1还有其他属性，继续添加

      console.log(formData);
      return new Promise((resolve, reject) => {
        //请求OSS
        this.axios({
          method: "post",
          url: this.objData.host,
          headers: {
            "Content-type": 'multipart/form-data'
          },
          data: formData
        }).then(function (resp) {
          console.log('21232132', resp);
          resolve(resp);
        }).catch(error => {
          console.log('21232132', error); // error from promise.then() or.catch()
          reject(error);
        });
      });
    },
    onSubmit1(values) {
      console.log("submit1", values);
    },
    onSubmit2(values) {
      console.log("submit2", values);
    },
    onSubmit3(values) {
      console.log("submit2", values);
    }
  }
};