// import { mapState } from "vuex";

export default {
  name: "app"
  // mounted() {
  //   console.log("this.cacheList:", this.cacheList);
  // },
  // computed: {
  //   ...mapState(["cacheList"]),
  // },
};