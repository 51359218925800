import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
// import candidate from "../../../components/store/candidate/candidate.vue";
export default {
  components: {},
  props: {},
  setup() {
    const active = ref(0);
    return {
      active
    };
  },
  created() {
    // console.log(this.$route);
    if (this.$route.path === "/dashboard/votePage") {
      this.$router.push("/votePage/voteIndex");
    }
  },
  data() {
    return {};
  }
};