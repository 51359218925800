import {
  createWebHashHistory,
  createRouter
} from 'vue-router'
import { createApp } from 'vue';
import { Dialog, showConfirmDialog } from 'vant';

const app = createApp();
app.use(Dialog);

import { useCookies } from '@vueuse/integrations/useCookies'
import store from './../store/store.js'
import axios from 'axios';
// 引入一级组件
import DashBoard from '../views/dashboard/DashBoard.vue'
import vote from './../views/apps/vote/vote.vue'
import set from './../views/my/morePage/set.vue'
import uni from './../views/my/uniPage/uni.vue'

const cookies = useCookies()

//懒加载（点击的时候才需要加载，提升性能）
const Index = () => import('./../views/index/myIndex.vue')
const Store = () => import('./../views/store/myStore.vue')
const Attention = () => import('./../views/attention/myAttention.vue')
const My = () => import('./../views/my/myPage.vue')
const Apps = () => import('./../views/apps/myApps.vue')
const addPost = () => import('./../views/addPost/addPost.vue')
const ArticleDetail = () => import('./../views/ArticleDetail/ArticleDetail.vue')
const AnswerDetail = () => import('./../views/AnswerDetail/AnswerDetail.vue')
const videoDetail = () => import('./../views/VideoDetail/VideoDetail.vue')
const videoComments = () => import('../components/VideoComponents/VideoComments.vue')
const videoIntroduction = () => import('../components/VideoComponents/VideoIntroduction.vue')
const payPage = () => import('./../views/payPage/payPage.vue')
const voteIndex = () => import('./../views/apps/vote/voteIndex/voteIndex.vue')
const voteSquare = () => import('./../views/apps/vote/voteSquare/voteSquare.vue')
const voteMy = () => import('./../views/apps/vote/voteMy/voteMy.vue')
const pickvotekind = () => import('./../views/apps/vote/pickvotekind/pickvotekind.vue')
const union1 = () => import('./../views/apps/union/union1/union1.vue')
const releaseVoteForm = () => import('./../views/apps/vote/releaseVoteForm/releaseVoteForm.vue')
const Login = () => import('../views/login/myLogin.vue')
const Register = () => import('../views/login/myRegister.vue')
const Retrieve = () => import('../views/login/myRetrieve.vue')
const securityPage = () => import('../views/my/morePage/securityPage/securityPage.vue')
const checkPassword = () => import('../views/my/morePage/securityPage/checkPassword.vue')
const checkNumber = () => import('../views/my/morePage/securityPage/checkNumber.vue')
const privacyPage = () => import('../views/my/morePage/privacyPage/privacyPage.vue')
const helpPage = () => import('../views/my/morePage/helpPage/helpPage.vue')
const checklistPage = () => import('../views/my/morePage/checklistPage/checklistPage.vue')
const aboutPage = () => import('../views/my/morePage/aboutPage/aboutPage.vue')
const setIndex = () => import('../views/my/morePage/setIndex/setIndex.vue')
const uniIndex = () => import('../views/my/uniPage/uniIndex/uniIndex.vue')
const applyUni = () => import('../views/my/uniPage/applyUni/applyUni.vue')
const checkUni = () => import('../views/my/uniPage/checkUni/checkUni.vue')
const failPage = () => import('../views/my/uniPage/applyFeedback/failPage.vue')
const successPage = () => import('../views/my/uniPage/applyFeedback/successPage.vue')
const ArticleCollection = () => import('./../views/ArticleDetail/ArticleCollection.vue')
const VideoCollection = () => import('./../views/VideoDetail/VideoCollection.vue')
const VideoCollectionDetail = () => import('./../views/VideoDetail/VideoCollectionDetail.vue')


const routes = [
  //重定向
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    //一级路由，方便后续注册登录等功能
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    children: [{
      path: '/dashboard',
      redirect: '/dashboard/myIndex',
      meta: {
        showTab: true,
        keepAlive: true,
        // requiresAuth: true, // 需要登录权限  
      }
    },

    {
      path: 'myIndex',
      name: 'index',
      component: Index,
      meta: {
        showTab: true,
        keepAlive: true,
      }
    },
    {
      path: 'myAttention',
      name: 'attention',
      component: Attention,
      meta: {
        showTab: true,
        // requiresAuth: true, // 需要登录权限  
        // authFields: ['accountLogin'],// 所需的登录信息字段
      }
    },
    {
      path: 'myStore',
      name: 'store',
      component: Store,
      meta: {
        showTab: true
      }
    },
    {
      path: 'myPage',
      name: 'my',
      component: My,
      meta: {
        showTab: true
      },

    },
    {
      path: 'myApps',
      name: 'apps',
      component: Apps,
      meta: {
        showTab: true
      }
    },
    {
      path: 'articleDetail/:articleInfo',
      name: 'ArticleDetail',
      component: ArticleDetail,

      meta: {
        showTab: false,
        keepAlive: true
      }
    },
    {
      path: 'ArticleCollection/:articleCollectionInfo',
      name: 'ArticleCollection',
      component: ArticleCollection,

      meta: {
        showTab: false,
        keepAlive: true
      }
    },
    {
      path: 'answerDetail',
      name: 'AnswerDetail',
      component: AnswerDetail,
      meta: {
        showTab: false,
      }
    },
    {
      path: 'videoDetail/:serializedObject',
      name: 'VideoDetail',
      component: videoDetail,
      props: true,
      meta: {
        showTab: false,
        requiresAuth: true,
      }
    },
    {
      path: 'VideoCollectionDetail/:VideoCollectionDetailObject',
      name: 'VideoCollectionDetail',
      component: VideoCollectionDetail,
      props: true,
      meta: {
        showTab: false,
        requiresAuth: true,
      }
    },
    {
      path: 'VideoCollection/:serializedObject',
      name: 'VideoCollection',
      component: VideoCollection,

      meta: {
        showTab: false,
        keepAlive: true
      }
    },
    {
      path: 'videoComments',
      name: 'videoComments',
      component: videoComments,
      meta: {
        showTab: false
      }
    },
    {
      path: 'videoIntroduction',
      name: 'videoIntroduction',
      component: videoIntroduction,
      meta: {
        showTab: false
      }
    },
    {
      path: 'payPage',
      name: 'payPage',
      component: payPage,
      meta: {
        showTab: false
      }
    },
    {
      path: 'addPost',
      name: 'addPost',
      component: addPost,
      meta: {
        showTabTab: false,
        requiresAuth: true, // 需要登录权限  
        // authFields: ['accountLogin'],// 所需的登录信息字段
      }
    },
    {
      path: 'myLogin',
      name: 'myLogin',
      component: Login,
      meta: {
        showTabTab: false
      }
    },
    {
      path: 'myRegister',
      name: 'myRegister',
      component: Register,
      meta: {
        showTabTab: false
      }
    }, {
      path: 'myRetrieve',
      name: 'myRetrieve',
      component: Retrieve,
      meta: {
        showTabTab: false
      }
    },
    {
      path: 'votePage',
      name: 'votePage',
      redirect: '/dashboard/votePage/voteIndex',
      component: vote,
      children: [{
        path: 'voteIndex',
        name: 'voteIndex',
        component: voteIndex,
        meta: {
          showVoteTab: true
        }
      },
      {
        path: 'voteSquare',
        name: 'voteSquare',
        component: voteSquare,
        meta: {
          showVoteTab: true
        }
      },
      {
        path: 'voteMy',
        name: 'voteMy',
        component: voteMy,
        meta: {
          showVoteTab: true
        }
      },
      {
        path: 'pickvoteKind',
        name: 'pickvoteKind',
        component: pickvotekind,
        meta: {
          showVoteTab: false
        }
      },
      {
        path: 'releaseVoteForm',
        name: 'releaseVoteForm',
        component: releaseVoteForm,
        meta: {
          showVoteTab: false
        }
      },
      ]
    },
    {
      path: 'setPage',
      name: 'setPage',
      redirect: '/dashboard/setPage/setIndex',
      component: set,
      children: [{
        path: 'setIndex',
        name: 'setIndex',
        component: setIndex,
        meta: {
          showTab: false
        }
      },
      {
        path: 'securityPage',
        name: 'securityPage',
        component: securityPage,
        meta: {
          showTab: false
        }
      },
      {
        path: 'checkPassword',
        name: 'checkPassword',
        component: checkPassword,
        meta: {
          showTab: false
        }
      },
      {
        path: 'checkNumber',
        name: 'checkNumber',
        component: checkNumber,
        meta: {
          showTab: false
        }
      },
      {
        path: 'privacyPage',
        name: 'privacyPage',
        component: privacyPage,
        meta: {
          showTab: false
        }
      },
      {
        path: 'helpPage',
        name: 'helpPage',
        component: helpPage,
        meta: {
          showTab: false
        }
      },
      {
        path: 'checklistPage',
        name: 'checklistPage',
        component: checklistPage,
        meta: {
          showTab: false
        }
      },
      {
        path: 'aboutPage',
        name: 'aboutPage',
        component: aboutPage,
        meta: {
          showTab: false
        }
      },
      ]
    },
    {
      path: 'uniPage',
      name: 'uniPage',
      redirect: '/dashboard/uniPage/uniIndex',
      component: uni,
      children: [
        {
          path: 'uniIndex',
          name: 'uniIndex',
          component: uniIndex,
          meta: {
            showTab: false
          }
        },
        {
          path: 'applyUni',
          name: 'applyUni',
          component: applyUni,
          meta: {
            showTab: false,
            requiresAuth: true, // 需要登录权限  
          }
        },
        {
          path: 'checkUni',
          name: 'checkUni',
          component: checkUni,
          meta: {
            showTab: false,
            requiresAuth: true, // 需要登录权限  
          }
        },
        {
          path: 'failPage',
          name: 'failPage',
          component: failPage,
          meta: {
            showTab: false
          }
        },
        {
          path: 'successPage',
          name: 'successPage',
          component: successPage,
          meta: {
            showTab: false
          }
        },
      ]
    },
    {
      path: 'union1',
      name: 'union1',
      component: union1,
      meta: {
        showTab: false
      }
    },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory('/'),
  routes
})


router.beforeEach(async (to, from, next) => {


  if (to.meta.requiresAuth) {
    const accessToken = cookies.get('access_token');
    const expiresIn = cookies.get('expires_in');
    const accountLogin = cookies.get('accountLogin');
    const reloginTime = cookies.get('reloginTime'); // 获取登录时间  
    const refreshNewDate = new Date(reloginTime + expiresIn * 1000)

    console.log('reloginTime', reloginTime);
    console.log('refreshNewDate', refreshNewDate);

    if (!accessToken || !reloginTime) {
      console.log('reloginTime不存在', reloginTime);
      // accessToken 和reloginTime不存在，跳转到登录页面  
      try {
        await showConfirmDialog({
          title: '未登录',
          message: '当前用户并未登录是否前往登录界面',
        });
        next('/dashBoard/myLogin'); // 点击确认，进行跳转  
      } catch (error) {
        // console.error(error);
        next(false); // 点击取消，不进行跳转，并且明确拒绝该导航  
      }
    }
    else if (new Date(new Date(reloginTime).getTime() + expiresIn * 1000) < new Date()) { // 基于登录时间来计算是否需要刷新token  
      // accessToken 存在但已过期，尝试刷新 token      
      console.log('过期', expiresIn);
      axios({
        method: "post",
        url: 'https://farm-service-oc.xueshangkeji.com/auth/login',
        data: {
          password: "farm",
          rememberMe: true,
          username: accountLogin,
        },
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          console.log('response成功', response);
          cookies.remove('expires_in');
          cookies.remove('access_token');
          cookies.remove('reloginTime');
          const { access_token, expires_in } = response.data;
          cookies.set('access_token', access_token);
          cookies.set('expires_in', expires_in);
          const newLoginTime = new Date(); // 更新登录时间  
          cookies.set('reloginTime', newLoginTime);
          // console.log('refresh', access_token);
          next(); // 导航继续      
        })
        .catch(error => {
          console.error('刷新失败', error);
          next('/dashBoard/myLogin'); // 刷新 token 失败，跳转到登录页面      
        });
    } else {
      console.log('token未过期，请继续');
      // accessToken 存在且未过期，导航继续      
      console.log('后', new Date(reloginTime + expiresIn * 1000));
      console.log('refreshNewDate', expiresIn);
      console.log('reloginTime', reloginTime);
      console.log('后', new Date(new Date(reloginTime).getTime() + expiresIn * 1000));
      console.log('前', new Date());
      next();
    }
  } else {
    // 不需要登录权限，导航继续     
    // console.log('不需要登录权限');
    next();
  }


});

// router.beforeEach((to, from, next) => {
//   if (from.meta.keepAlive) {
//     const $wrapper = document.querySelector('.article'); // 列表的外层容器 注意找到滚动的盒子
//     const scrollTop = $wrapper ? $wrapper.scrollTop : 0;
//     console.log('scrollTop=', scrollTop)
//     from.meta.scrollTop = scrollTop;
//   }
//   next();
// });


// router.beforeEach(async (to, from, next) => {
//   if (to.meta.requiresAuth) {
//     const accessToken = cookies.get('access_token');
//     const expiresIn = cookies.get('expires_in');
//     const accountLogin = cookies.get('accountLogin');

//     if (!accessToken) {
//       // accessToken 不存在，需要重新登录  
//       next('/dashBoard/myLogin'); // 跳转到登录页面  
//     } else {
//       // 检查 token 是否过期  
//       const now = Math.floor(Date.now() / 1000); // 当前时间戳（秒）  
//       if (now > parseInt(expiresIn)) {
//         // token 已过期，需要重新登录并获取新的 accessToken 和过期时间  
//         try {
//           const response = await axios({
//             method: "post",
//             url: 'http://farm-service-oc.xueshangkeji.com/auth/login',
//             data: {
//               username: accountLogin,
//               password: "farm",
//               rememberMe: true,
//             },
//             headers: { 'Content-Type': 'application/json' }
//           });
//           const { access_token, token_type, refresh_token, expires_in, iat, jti } = response.data;
//           cookies.set('access_token', access_token);
//           cookies.set('token_type', token_type);
//           cookies.set('refresh_token', refresh_token);
//           cookies.set('expires_in', expires_in);
//           cookies.set('iat', iat);
//           cookies.set('jti', jti);
//           next(); // 继续导航  
//         } catch (error) {
//           console.error(error);
//           next('/dashBoard/myLogin'); // 登录失败，跳转到登录页面  
//         }
//       } else {
//         // token 未过期，调用接口获取数据  
//         try {
//           const response = await axios.get('http://farm-service-oc.xueshangkeji.com/uaa/api/account', {
//             headers: {
//               Authorization: "Bearer " + accessToken,
//               "Access-Control-Allow-Origin": "*",
//               "Content-Type": "application/json;charset=UTF-8",
//             },
//           });
//           // 处理 login 值，或者将其传递给下一个路由组件处理  
//           next(); // 继续导航  
//         } catch (error) {
//           console.error(error);
//           next('/dashBoard/myLogin'); // 接口调用失败，跳转到登录页面  
//         }
//       }
//     }
//   } else {
//     // 不需要登录权限，可以直接导航  
//     next();
//   }
// });

// router.beforeEach(async (to, from, next) => {
//   // 检查是否需要登录权限  
//   if (to.meta.requiresAuth) {
//     const access_tocken = cookies.get('access_tocken');
//     const expires_in = cookies.get('expires_in');
//     // 检查cookies是否存在且未过期  
//     if (!access_tocken || new Date(Date.now() + expires_in * 1000) < new Date()) {
//       // cookies不存在或者已过期，需要重新登录  
//       // ...  
//       next('/dashBoard/myLogin'); // 跳转到登录页面  
//     } else {
//       // cookies存在且未过期，可以继续导航  
//       next();
//     }
//   } else {
//     // 不需要登录权限，可以直接导航  
//     next();
//   }
// });

export default router