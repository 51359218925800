import { createApp } from 'vue';
import App from './App.vue';
import store from './store/store.js';
import router from './router/index'
import * as vant from 'vant';
// import { Tabbar, TabbarItem } from 'vant';
import 'vant/lib/index.css';
import './plugins/FastClick';
import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
import axios from 'axios';

import VueAxios from "vue-axios";



axios.defaults.baseURL = '/api'
const app = createApp(App);

app.use(router).use(store).use(vant).use(VideoPlayer).use(VueAxios, axios).mount('#app');



