import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1364b5ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  id: "votePage"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.$route.meta.showVoteTab ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tabbar, {
    modelValue: $setup.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.active = $event),
    "active-color": "#FF5A3E"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "wap-home",
      replace: "",
      to: "voteIndex"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "fire",
      replace: "",
      to: "voteSquare",
      dot: ""
    }, {
      default: _withCtx(() => [_createTextVNode("广场")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "user-o",
      replace: "",
      to: "voteMy"
    }, {
      default: _withCtx(() => [_createTextVNode("我的")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])])) : _createCommentVNode("", true), _createVNode(_component_router_view)], 64);
}