// store.js  
import { createStore } from 'vuex'
import { login } from '../api/api.js' // 导入登录 API  

const store = createStore({
    state: {
        token: null, // 用于存储 access_token  
        expiresIn: null // 用于存储 token 的过期时间  
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_EXPIRES_IN(state, expiresIn) {
            state.expiresIn = expiresIn
        }
    },
    actions: {
        SET_TOKEN({ commit }, token) {
            commit('SET_TOKEN', token);
        },
        SET_EXPIRES_IN({ commit }, expiresIn) {
            commit('SET_EXPIRES_IN', expiresIn);
        },
    }
})

export default store