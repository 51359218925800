import { ref, onMounted, watch } from "vue";
import { showFailToast } from "vant";
import AddPost from "../addPost/addPost.vue";
import { useCookies } from '@vueuse/integrations/useCookies';
const cookies = useCookies(); // 使用 useCookies 获取 cookies 对象

export default {
  setup() {
    const active = ref(Number(localStorage.getItem('active')) || 0); // 从localStorage获取active的值，如果没有则默认为0

    const index_icon = {
      active: require("@/assets/img/tabbar/index_check.png"),
      inactive: require("@/assets/img/tabbar/index.png")
    };
    const attention_icon = {
      active: require("@/assets/img/tabbar/focus_check.png"),
      inactive: require("@/assets/img/tabbar/focus.png")
    };
    const store_icon = {
      active: require("@/assets/img/tabbar/store_check.png"),
      inactive: require("@/assets/img/tabbar/store.png")
    };
    const apps_icon = {
      active: require("@/assets/img/tabbar/edit_color.png"),
      inactive: require("@/assets/img/tabbar/edit_color.png")
    };
    const my_icon = {
      active: require("@/assets/img/tabbar/my_check.png"),
      inactive: require("@/assets/img/tabbar/my.png")
    };
    // 在mounted钩子中监听active的变化，并将其保存在localStorage中  
    onMounted(() => {
      watch(active, newVal => {
        cookies.set('active', newVal);
      });
    });
    return {
      index_icon,
      attention_icon,
      store_icon,
      apps_icon,
      my_icon,
      active
    };
  },
  methods: {
    addPost() {
      // this.$router.push('addPost');
      showFailToast('未开放');
    }
  }
  //初始化页面选中状态
  // created() {
  //   //由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
  //   console.log(this.$route); //打印当前路由属性
  //   if (this.$route.path === "/") {
  //     this.$router.push("");
  //   }
  // },
};